import { Suspense } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import {
  AboutPage,
  ContactPage,
  HomePage,
  ProjectDetailPage,
  ProjectsPage,
  ServiceDetailPage,
  ServicePage,
  TeamDetailPage,
  TeamPage,
} from "./Pages";
import { MainLayout } from "./Layout";
import { routeList } from "./RouteList";


function App() {
  return (
    <>
      <Suspense fallback={<div className="preloader"></div>}>
        <Routes>
          <Route element={<MainLayout />}>
          <Route path={routeList.home} element={<HomePage />} />
            <Route path={routeList.contact} element={<ContactPage />} />
            <Route path={routeList.about} element={<AboutPage />} />
            <Route path={routeList.team} element={<TeamPage />} />
            <Route path={routeList.project} element={<ProjectsPage />} />
            <Route path={routeList.service} element={<ServicePage />} />
            <Route path={`${routeList.projectDetail}/:id`} element={<ProjectDetailPage />}/>
            <Route path={`${routeList.serviceDetail}/:id`} element={<ServiceDetailPage />}/>
            <Route path={`${routeList.teamDetail}/:id`} element={<TeamDetailPage />}/>
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
