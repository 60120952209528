import { lazy } from "react";

export const HomePage = lazy(() => import("./Home/index.page"));
export const ContactPage = lazy(() => import("./ContactUs/index.page"));
export const AboutPage = lazy(() => import("./About/index.page"));
export const ProjectsPage = lazy(() => import("./Projects/index.page"));
export const ServicePage = lazy(() => import("./Service/index.page"));
export const TeamPage = lazy(() => import("./Team/index.page"));
export const ProjectDetailPage = lazy(() => import("./ProjectDetail/index.page"));
export const ServiceDetailPage = lazy(() => import("./ServiceDetail/index.page"));
export const TeamDetailPage = lazy(() => import("./TeamDetail/index.page"));