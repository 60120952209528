export const routeList = {
  home: "/",
  contact: "/contact-us",
  about: "/about",
  team: "/team",
  teamDetail: "/team-detail",
  project: "/projects",
  projectDetail: "/project-detail",
  service: "/services",
  serviceDetail: "/service-detail",
};
